import { GridLayoutAnimationSettings } from "../../../../full/src/components/grid-layout/grid-layout-settings";
import {
  CardStyle,
  ConditionalCardStyle,
} from "../../../../full/src/components/order-card/order-card-settings";
import { logWarning } from "../../../../full/src/services/logging";
import { MyOrderResponseOrder } from "../../../../full/src/services/wosb-connection-contracts";
import { IComponent } from "../component-interfaces";
import { CardSpotRect } from "../order-grid/order-grid";
import { testConditions } from "../../../../full/src/helpers/condition-helpers";
import { getValueWithAppliedTemplate } from "../../../../full/src/helpers/string-template-helpers";

export class OrderCard implements IComponent {
  private orders: MyOrderResponseOrder[] = [];
  private cardStyle: CardStyle;
  private root: HTMLElement | undefined;
  private activityContainer: HTMLElement | undefined;
  private orderNameContainer: HTMLElement | undefined;
  private currentFontSizeMultiplier: number = 1;

  constructor(order: MyOrderResponseOrder, cardStyle: CardStyle) {
    if (!order || !order.orderId) {
      throw new Error(
        "Not allowed to create an OrderCard without supplying an order and the orderId property must also be populated. Received: " +
          JSON.stringify(order)
      );
    }
    this.orders = [order];
    this.cardStyle = cardStyle;
    this.reloadUI();
  }

  getRootElement: () => HTMLElement = () => {
    if (this.root === undefined) {
      const card = document.createElement("div");
      card.className = "order-card";
      const middle = document.createElement("div");
      middle.className = "order-card-middle";
      const contentContainer = document.createElement("div");
      contentContainer.className = "order-card-content-container";
      const activityContainer = document.createElement("div");
      activityContainer.className = "order-card-activity-text";
      const orderNameContainer = document.createElement("div");
      orderNameContainer.className = "order-card-name";

      card.appendChild(middle);
      middle.appendChild(contentContainer);
      contentContainer.appendChild(activityContainer);
      contentContainer.appendChild(orderNameContainer);
      this.activityContainer = activityContainer;
      this.orderNameContainer = orderNameContainer;
      this.root = card;
      this.reloadUI();
    }
    return this.root;
  };

  setOrderData(newData: MyOrderResponseOrder) {
    if (!newData || !newData.orderId) {
      throw new Error(
        "Cannot use setOrderData without providing an order to the call. The order must also have an orderId value set."
      );
    }
    const index = this.orders.findIndex((o) => o.orderId === newData?.orderId);
    if (index === -1) {
      throw new Error(
        `This order (${
          newData.orderId
        }) does not exist in this order card. This order card contains the following orders: ${this.orders
          .map((o) => o.orderId)
          .join(", ")}`
      );
    }
    this.orders[index] = newData;
    this.reloadUI();
  }

  private reloadUI() {
    if (this.orders.length === 0) {
      throw new Error(
        "An order card must always have at least one order. Tried to reload the UI when there are no orders in the card. This is an invalid state."
      );
    }
    const firstOrder = this.orders[0];

    const newClassName = firstOrder.status
      ? "order-card order-card-status-" + firstOrder.status.toLowerCase()
      : null;
    this.getRootElement().className = newClassName;
    this.applyCardStyleAndName(this.cardStyle);
  }

  private updateCardName(nameTextTemplate: string) {
    if (this.orderNameContainer) {
      if (this.orders?.length > 1) {
        this.orderNameContainer.innerText = "+" + this.orders.length;
      } else if (this.orders?.length == 1) {
        const firstOrder = this.orders[0];
        this.orderNameContainer.innerText = nameTextTemplate
          ? getValueWithAppliedTemplate(nameTextTemplate, firstOrder) ??
            firstOrder.name
          : firstOrder.name;
      } else {
        this.orderNameContainer.innerText = "";
      }
    }
  }

  addChildOrder(order: MyOrderResponseOrder, placement: "first" | "last") {
    if (placement === "first") {
      this.orders.unshift(order);
    } else {
      this.orders.push(order);
    }
    this.reloadUI();
  }

  containsOrderId(orderId: string) {
    return Boolean(this.orders.find((o) => o.orderId === orderId));
  }

  removeChildOrderIfContained(orderId: string) {
    const index = this.orders?.findIndex((o) => o.orderId === orderId) || -1;
    if (index === -1) {
      return null;
    } else {
      if (this.orders.length === 1) {
        throw new Error(
          "An order card must always have at least one order. Not allowed to remove the last order from the order card. Then just remove the order card instead."
        );
      }
      const removedOrder = this.orders.splice(index, 1);
      this.reloadUI();
      return removedOrder[0];
    }
  }

  getFirstOrder() {
    return this.orders[0];
  }

  getOrderCount() {
    return this.orders.length;
  }

  removeFirstOrder() {
    if (this.orders.length === 1) {
      throw new Error(
        "After removing the first order, the OrderCard must still have at least one order. Not allowed to remove the only order from the order card."
      );
    }
    const order = this.orders.splice(0, 1);
    this.reloadUI();
    return order[0];
  }

  setCardStyle(cardStyle: CardStyle) {
    this.cardStyle = cardStyle;
    this.applyCardStyleAndName(cardStyle);
  }

  private applyCardStyleAndName(cardStyle: CardStyle) {
    let background = null;
    let borderRadius = null;
    let border = null;
    let fontSizeMultiplier: number | null = null;
    let color = null;
    let activityText = undefined;
    let nameTextTemplate = null;
    let fontWeight = null;
    let fontStyle = null;
    let fontFamily = null;

    const firstOrder = this.orders[0];

    if (cardStyle) {
      const cardStylesToApply = [cardStyle];
      const cardStylesToCheck: ConditionalCardStyle[] = [];

      if (cardStyle?.conditional) {
        cardStylesToCheck.push(...cardStyle.conditional);
      }
      const o: any = {
        ...firstOrder,
        isOrderGroup: this.orders.length > 1,
        orderCount: this.orders.length,
      };
      while (cardStylesToCheck.length > 0) {
        const conditionalStyle = cardStylesToCheck.shift();
        const shouldApplyStyle = testConditions(o, conditionalStyle.conditions);
        if (shouldApplyStyle) {
          if (conditionalStyle.style?.conditional?.length > 0) {
            cardStylesToCheck.push(...conditionalStyle.style.conditional);
          }
          cardStylesToApply.push(conditionalStyle.style);
        }
      }
      for (let i = 0; i < cardStylesToApply.length; i++) {
        const style = cardStylesToApply[i];
        if (style.background !== undefined) {
          background = style.background;
        }
        if (style.borderRadius !== undefined) {
          borderRadius = style.borderRadius;
        }
        if (style.color !== undefined) {
          color = style.color;
        }
        if (style.activityText !== undefined) {
          activityText = style.activityText;
        }
        if (style.nameText !== undefined) {
          nameTextTemplate = style.nameText;
        }
        if (style.border !== undefined) {
          border = style.border;
        }
        if (style.fontWeight !== undefined) {
          fontWeight = style.fontWeight;
        }
        if (style.fontStyle !== undefined) {
          fontStyle = style.fontStyle;
        }
        if (style.fontFamily !== undefined) {
          fontFamily = style.fontFamily;
        }
        if (style.fontSizeMultiplier !== undefined) {
          fontSizeMultiplier = style.fontSizeMultiplier;
        }
      }
    }
    const rootElement = this.getRootElement();
    rootElement.style.background = background;
    rootElement.style.border = border;
    if (this.activityContainer) {
      this.activityContainer.innerText = getValueWithAppliedTemplate(
        activityText,
        this.orders.length > 0 ? this.orders[0] : {}
      );
    }
    if (fontSizeMultiplier) {
      if (this.orderNameContainer) {
        this.orderNameContainer.style.fontSize = fontSizeMultiplier + "em";
      }
      rootElement.style.setProperty(
        "--font-size-multiplier",
        fontSizeMultiplier?.toString()
      );
    } else {
      if (this.orderNameContainer) {
        this.orderNameContainer.style.fontSize = null;
      }
      rootElement.style.removeProperty("--font-size-multiplier");
    }
    rootElement.style.color = color;
    rootElement.style.borderRadius = borderRadius;
    rootElement.style.fontFamily = fontFamily;
    rootElement.style.fontWeight = fontWeight;
    rootElement.style.fontStyle = fontStyle;
    this.updateCardName(nameTextTemplate);
  }

  static createStyleElement = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .order-card {
        display: table;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border: solid 5px gray;
      }
      .order-card-middle {
        display: table-cell;
        vertical-align: middle;
      }
      .order-card-content-container {
        text-align: center;
      }
      .order-card-activity-text:empty {
        display:none;
      }
      .order-card-name {
        line-height: 1em;
      }
    `;
    return style;
  };

  setPosition(targetRect: CardSpotRect) {
    const cardRoot = this.getRootElement();
    cardRoot.style.position = "absolute";
    cardRoot.style.top = targetRect.top + "px";
    cardRoot.style.left = targetRect.left + "px";
    cardRoot.style.width = targetRect.width + "px";
    cardRoot.style.height = targetRect.height + "px";
    const aspect = targetRect.width / targetRect.height;
    cardRoot.style.fontSize =
      this.currentFontSizeMultiplier *
        (targetRect.height / (2 / (aspect / 2))) +
      "px";
  }
}
