import { HorizontalSplitLayoutHeaderSetting } from "../../../../full/src/components/horizontal-split-layout/horizontal-split-layout-settings";
import { PortraitGridLayoutSettings } from "../../../../full/src/components/portrait-grid-layout/portrait-grid-layout-settings";
import { getFailingConditions, testConditions } from "../../../../full/src/helpers/condition-helpers";
import { logDebug, logError, logWarning } from "../../../../full/src/services/logging";
import { OrderChangeQueue } from "../../../../full/src/services/order-change-queue";
import { MyOrderResponseOrder, OrderChangeInfo } from "../../../../full/src/services/wosb-connection-contracts";
import { OrderStatusLayout } from "../component-interfaces";
import { OrderCard } from "../order-card/order-card";
import { OrderGrid } from "../order-grid/order-grid";

export class PortraitGridLayout implements OrderStatusLayout {
  private settings: PortraitGridLayoutSettings;
  constructor(settings: PortraitGridLayoutSettings) {
    this.settings = settings;
    this.grid = new OrderGrid(
      settings.grid,
      (o) => o.status === "inProgress" || o.status === "done"
    );
  }
  bottomPredicate: (order: MyOrderResponseOrder) => boolean = (o) =>
    o.status === "inProgress";
  topPredicate: (order: MyOrderResponseOrder) => boolean = (o) =>
    o.status === "done";
  private grid: OrderGrid;  
 
  async applyQueue(queue: OrderChangeQueue): Promise<{ abort: () => void }> {
    let hasBeenAborted = false;
    var internalPromise = new Promise<void>(async (resolve, reject) => {
      try {
        while (!hasBeenAborted) {
          const queueLength = queue.getCount();
          // TODO: Make the thresholds for animation speed multipliers configurable
          let speedMultiplier = 1;
          if (this.settings?.animationThresholds) {
            for (let i = 0; i < this.settings.animationThresholds.length; i++) {
              const current = this.settings.animationThresholds[i];
              if (queueLength >= current.queueCount) {
                speedMultiplier = current.speed;
              }
            }
          }
          this.setAnimationSpeedMultiplier(speedMultiplier);
          const change = await queue.dequeue();
          await this.applyChange(change);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
    return {
      abort: () => {
        hasBeenAborted = true;
      },
    };
  }

  async applyChange(change: OrderChangeInfo): Promise<void> {
    
    if (!change.order.orderId) {
      logWarning("The change did not contain any order id, ignoring.", change);
      return;
    }
    if (this.settings?.filter != null) {
      if (!testConditions(change.order, this.settings.filter)) {
        logDebug(
          "Ignoring order event since the filter applied to the layout states that the event should not be handled",
          {
            Order: change.order,
            ExcludedByFilters: getFailingConditions(
              change.order,
              this.settings.filter
            ),
          }
        );
        return;
      }
    }
    let currentOrderCard = this.grid.getOrderCardContainingOrderOrNull(change.order.orderId);
    let currentGrid = currentOrderCard ? this.grid : null;
    let targetGrid =
      change.type === "removed"
        ? null
        : this.grid.shouldIncludeOrder(change.order)
        ? this.grid
        : null;
    if (currentGrid === null && currentGrid === targetGrid) {
      // We shouldn't move anything, so we're done with the moving
    } else if (targetGrid && !currentGrid) {
      // We are supposed to add the item to a container
      if (targetGrid === this.grid) {
        await targetGrid.makeSpaceEmpty(0);
      }
      const orderCard = new OrderCard(change.order, targetGrid.getCardStyle());
      await targetGrid.addOrderCard(orderCard);
    } else if (currentGrid && !targetGrid) {
      // We're supposed to remove the item
      await currentGrid.removeOrderIfContained(change.order.orderId, true);
      await currentGrid.fillEmptySpaces();
    } else {
      // We're supposed to move the item
      await targetGrid.makeSpaceEmpty(0);
      const currentLocation = await currentGrid.removeOrderIfContained(
        change.order.orderId,
        false
      );
      if (currentLocation) {
        currentLocation.orderCard.setOrderData(change.order);
        await targetGrid.addOrderCard(
          currentLocation.orderCard,
          currentLocation.boundingClientRect
        );
        await currentGrid.fillEmptySpaces();
      } else {
        logError(
          new Error(
            "Tried removing order from a grid, but could not find the order to remove"
          ),
          { OrderId: change.order.orderId }
        );
      }
    }
  }
  setAnimationSpeedMultiplier(multiplier: number): void {
    this.grid.setAnimationSpeedMultiplier(multiplier);
  }
  
  private root: HTMLTableElement | undefined = undefined;
  getRootElement: () => HTMLElement = () => {
    if (this.root === undefined) {
      const createElement = <T extends HTMLElement>(
        elementName: string,
        style?: Partial<CSSStyleDeclaration> | undefined,
        funcOrChildren?: ((element: T) => void) | HTMLElement[] | undefined,
        children?: HTMLElement[] | undefined
      ) => {
        const element = document.createElement(elementName) as T;
        let func: ((element: T) => void) | undefined = undefined;
        if (funcOrChildren !== undefined) {
          if (typeof funcOrChildren === "function") {
            func = funcOrChildren;
          } else {
            children = funcOrChildren as HTMLElement[] | undefined;
          }
        }
        if (style) {
          Object.assign(element.style, style);
        }
        if (children) {
          children.forEach((c) => element.appendChild(c));
        }
        if (func) {
          func(element);
        }
        return element;
      };
      const applyHeaderSetting = (
        element: HTMLElement | null,
        header: HorizontalSplitLayoutHeaderSetting | undefined
      ): void => {
        if (element) {
          if (header?.text) {
            element.innerText = header.text;
          } else {
            element.innerText = null;
          }
          const apply = (
            value: string | undefined,
            setter: (val: string | null) => void
          ): void => {
            if (value) {
              setter(value);
            } else {
              setter(null);
            }
          };
          apply(header?.align, (v) => {
            if (v) {
              switch (v) {
                case "center":
                  element.style.verticalAlign = "center";
                  break;
                case "start":
                  element.style.verticalAlign = "top";
                  break;
                case "end":
                  element.style.verticalAlign = "bottom";
                  break;
                default:
                  break;
              }
            }
          });
          apply(header?.justify, (v) => {
            switch (v) {
              case "center":
                element.style.textAlign = "center";
                break;
              case "left":
              case "start":
                element.style.textAlign = "left";
                break;
              case "right":
              case "end":
                element.style.textAlign = "right";
                break;
              default:
                break;
            }
          });
          apply(header?.fontFamily, (v) => (element.style.fontFamily = v));
          apply(header?.fontWeight, (v) => (element.style.fontWeight = v));
          apply(header?.fontStyle, (v) => (element.style.fontStyle = v));
          apply(header?.fontSize + "px", (v) => (element.style.fontSize = v));
          apply(header?.color, (v) => (element.style.color = v));
          apply(header?.padding, (v) => (element.style.padding = v));
        }
      };

      // TODO: Handle window resize
      const [rowsTemplate, columnsTemplate] =
        this.settings.rootGridTemplate.split("/");
      const evaluateSizing = (template: string[], availableSpace: number) => {
        const parsed = template.map((val) => {
          const matches = val.match(/(\d+)px|(\d+)fr/);
          if (
            matches == null ||
            matches.length != 3 ||
            (matches[1] === undefined && matches[2] === undefined)
          ) {
            throw new Error(
              `Grid template value '${val}' from template '${template.join(
                " "
              )}' is not supported. Only px and fr values are supported.`
            );
          }
          if (matches[1] !== undefined) {
            return { value: parseInt(matches[1]), type: "px" };
          } else {
            return { value: parseInt(matches[2]), type: "fr" };
          }
        });
        const totalFixedSpace = parsed.reduce(
          (acc, curr) => (curr.type === "px" ? acc + curr.value : acc),
          0
        );
        const totalFractions = parsed.reduce(
          (acc, curr) => (curr.type === "fr" ? acc + curr.value : acc),
          0
        );
        const spacePerFraction = Math.max(
          0,
          (availableSpace - totalFixedSpace) / totalFractions
        );
        return parsed.map((p) =>
          p.type === "px" ? p.value : p.value * spacePerFraction
        );
      };
      const bodySize = document.body.getBoundingClientRect();
      const rowSizings = evaluateSizing(
        rowsTemplate.split(" ").filter((s) => s !== ""),
        bodySize.height
      );
      const columnSizings = evaluateSizing(
        columnsTemplate.split(" ").filter((s) => s !== ""),
        bodySize.width
      );
      if (rowSizings.length !== 3) {
        throw new Error(
          "Horizontal split layout received invalid row values in the template. Grid template must have 3 rows."
        );
      }
      if (columnSizings.length !== 3) {
        throw new Error(
          "Horizontal split layout received invalid column values in the template. Grid template must have 3 columns."
        );
      }
      const gridPlace = createElement("td");
      gridPlace.appendChild(this.grid.getRootElement());
      this.root = createElement(
        "table",
        {
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          borderSpacing: "0",
        },
        [
          createElement(
            "colgroup",
            undefined,
            columnSizings.map((s) => createElement("col", { width: s + "px" }))
          ),
          createElement("tr", { height: rowSizings[0] + "px" }, [
            // Main header row
            createElement("td"),
            createElement<HTMLTableCellElement>("td", {}, (e) => {
              // Main header
              applyHeaderSetting(e, this.settings?.header);
              e.colSpan = 3;
            }),
            createElement("td"),
          ]),
          createElement("tr", { height: rowSizings[1] + "px" }, [
            // Main content row
            createElement("td"),
            gridPlace,
            createElement("td"),
          ]),
          createElement("tr", { height: rowSizings[2] + "px" }, [
            // Footer row
            createElement<HTMLTableCellElement>(
              "td",
              {},
              (e) => (e.colSpan = 5)
            ),
          ]),
        ]
      ) as HTMLTableElement;
    }
    return this.root;
  };

  static createStyleElement = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .order-card-activity-text {
        font-size: 30px; 
      }
    `;
    return style;
  };
}
